<div [ngSwitch]="isAuthenticated()" class="">
    <div *ngSwitchCase="false"  class="divAccueil">
        <head>
            <meta charset="UTF-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <title>DUPLOS</title>
            <!-- Style -->
            <!--link rel="stylesheet" href="CSS/style.css" /-->
            <link
            href="https://www.dafontfree.net/embed/bXVzZW8tNzAwJmRhdGEvNTAvbS8yODY5Ni9NdXNlbzcwMC1SZWd1bGFyLm90Zg"
            rel="stylesheet"
            type="text/css"
            />
            <link
            href="https://fonts.googleapis.com/css2?family=Outfit:wght@400;500;600;700&display=swap"
            rel="stylesheet"
            />
        </head>

        <header>
        <nav>
            <span>
            <a href="index.html" style="margin-right:10px"
            ><img id="logo" src="/assets/images/LOGO.png" alt="Logo Duplos"
            /></a>
            <a href="index.html" style="margin:10px"
            ><img id="logoRF" src="/assets/images/Logo_RepFranc.png" alt="Logo RF"
            /></a>
            <a href="index.html" style="margin:10px"
            ><img id="logoBRGM" src="/assets/images/Logo_brgm_quadri_FR.png" alt="Logo BRGM"
            /></a>
        </span>
            <ul>
            <li>
                <a
                href="https://assistance.brgm.fr/aide/DUPLOS"
                target="_blank"
                class="boutonsec"
                >Besoin d'aide ?</a
                >
            </li>
            <!-- Bouton qui ouvre la popup -->
            <li><button (click)="create()">Créer un compte</button></li>
            <li>
                <a                
                class="bouton" (click)="login()"
                >Connexion</a
                >
            </li>
            </ul>
        </nav>
        
        </header>
        
        <main>
            <div class="banniere">
                <a href="#attribtionCodeBSS" style="text-decoration:none"><span class="text-wrapper">Webinaire DUPLOS pour la nouvelle année</span></a>
                <a href="#attribtionCodeBSS" style="text-decoration:none"><div class="bouton"><div class="cr-er-un-compte">Lire l'actualité</div></div></a>
              </div>
  
        <!-- Contenu de la popup -->
        <div id="maPopup" class="popup">
            <div>
            <h3>Création de nouveau compte</h3>
            <p>
                Nous vous suggérons fortement de saisir un compte générique pour
                l'ensemble de vos collaborateurs qui seront amenés à utiliser.
            </p>
            <ul>
                <li>Plus simple à gérer pour vous</li>
                <li>Moins de compte en déshérence pour nous !</li>
            </ul>
            <div>
                <a
                href="https://passeport.brgm.fr/admldap-inscription/inscription/ldapbrgm.brgm.fr/form?application=duplos&application=dialog&profile=DUPLOS_UTILISATEUR&profile=Utilisateur_dialog"
                target="_blank"
                ><button class="bouton" (click)="create()">
                    Créer un compte
                </button></a
                >
                <button class="boutonsec" onclick="fermerPopup()">Annuler</button>
            </div>
            </div>
        </div>
        <!-- SECTION 1 - HERO SECTION -->
        <section id="S1-hero">
            <div>
                <h1>
                    <span>D</span>éclaration <span>U</span>nifiée<br /><span>P</span>our
                    <span>L</span>es <span>O</span>uvrages <span>S</span>outerrains
                </h1>
                <img
                    src="/assets/images/Illustration.png"
                    alt="forage et homme saisissant sa déclaration"
                    object-fit="cover"
                    height="80%"
                />
                <div>
                    <h1>
                    <span>D</span>éclaration <span>U</span>nifiée<br /><span>P</span
                    >our <span>L</span>es <span>O</span>uvrages
                    <span>S</span>outerrains
                    </h1>
                    <p>
                        DUPLOS est un téléservice qui vous permet de déclarer un projet de forage(s) aux titres du Code minier (<a href="https://www.legifrance.gouv.fr/codes/article_lc/LEGIARTI000023505812/">article L411-1</a>) et/ou du Code général des collectivités territoriales (<a href="https://www.legifrance.gouv.fr/codes/article_lc/LEGIARTI000047303736">Article L2224-9</a>), respectivement pour ouvrages souterrains de plus de 10 m de profondeur ou des forages domestiques. Ce téléservice permet de simplifier et unifier la procédure administrative. Le service DUPLOS est actuellement accessible sur toute la France métropolitaine et DROM.
                    </p>
                    
                    <!--p>
                        Lien vers les textes réglementaires:
                    </p>
                    <p>
                        <a style="font-weight: normal;" target="_blank" href="https://www.legifrance.gouv.fr/codes/article_lc/LEGIARTI000023505812/">Code minier</a>
                    </p>
                    <p>
                        <a style="font-weight: normal;" target="_blank" href="https://www.legifrance.gouv.fr/codes/article_lc/LEGIARTI000047303736">Code général des collectivités territoriales</a>
                    </p-->
                </div>
            </div>
            <div class="scroll-down">
                
                <a href="#attribtionCodeBSS"><div class="voirTouteActu">Voir toute l'actualité</div>
                    <img src="/assets/images/down.svg" alt="scroll down" class="scroll-down-image"/></a>
            </div>
        </section>
        <!-- SECTION - BOUTONS MOBILE -->
        <section id="btn-mob">
            <ul>
            <li>
                <a
                (click)="login()"
                class="bouton"
                >Connexion</a
                >
            </li>
            <!-- Bouton qui ouvre la popup -->
            <li><button (click)="create()">Créer un compte</button></li>
            <li>
                <a
                href="https://assistance.brgm.fr/aide/DUPLOS"
                class="boutonsec"
                target="_blank"
                >Besoin d'aide ?</a
                >
            </li>
            </ul>
        </section>
        <!-- SECTION 2 - DECLARER -->
        <section id="S2-declarer">
            <h2>
            <img src="/assets/images/Polygon Rose.png" alt="icone-deco" />
            Déclarer
            </h2>
            <!-- Bloc 1 -->
            <div>
            <h3>
                Des ouvrages souterrains à usage domestique et/ou de plus de 10 mètres de profondeur
            </h3>
            <div id="div-bouton">
                <button (click)="create()">Créer un compte</button>
                <a
                (click)="login()"
                class="bouton"
                >Connexion</a
                >
            </div>
            </div>
            <!-- Bloc 2 -->
            <h4>
            Déclarer des ouvrages au titre d'autres réglementations (hors DUPLOS)
            </h4>
            <div id="autredeclabloc">
            <!--div class="autredecla">
                <p>
                Vous souhaitez déclarer des puits ou forages à usage domestique
                </p>
                <a
                class="boutonsec"
                href="https://www.service-public.fr/particuliers/vosdroits/R20077"
                >Télécharger le formulaire</a
                >
            </div-->
            <div class="autredecla">
                <p>
                Vous souhaitez déclarer une installation relevant de la <strong>géothermie
                de minime importance</strong> (Portail GMI)
                </p>
                <a
                class="boutonsec"
                href="https://geothermie.developpement-durable.gouv.fr/"
                >Déclarer sur TéléGMI</a
                >
            </div>
            </div>
        </section>
        <!-- S3-COMMENT FAIRE -->
        <section id="S3-commentFaire">
            <h2>
            <img src="/assets/images/Polygon Bleu.png" alt="icone-deco" />
            Comment faire ?
            </h2>
            <!-- bloc Vidéos -->
            <div id="video">
            <a
                href="https://www.youtube.com/watch?v=JrHOXo3smbs&t=62s"
                target="_blank"
                ><img src="/assets/images/IMG-VIDEO.png" alt="Lien vers vidéo" />
                <p>Présentation de DUPLOS</p></a
            >
            <a
                href="https://www.youtube.com/watch?v=Bu5LG0cOZWQ&t=2s"
                target="_blank"
                ><img src="/assets/images/IMG-VIDEO.png" alt="Lien vers vidéo" />
                <p>Première déclaration</p></a
            >
            <a
                href="https://www.youtube.com/watch?v=zXhmURIgF2k&t=1s"
                target="_blank"
                ><img src="/assets/images/IMG-VIDEO.png" alt="Lien vers vidéo" />
                <p>Utiliser DiaLog</p></a
            >
            </div>
            <div id="info">
            <h3>Information réglementaire importante</h3>
            <ul>
                <li>
                Cette déclaration
                <strong
                    >ne vaut pas autorisation ou déclaration de réaliser l'ouvrage
                    au titre des autres réglementations</strong
                >
                qui peuvent s'appliquer à votre projet (loi sur l’eau, ICPE, code
                minier L162-10, code de la santé publique).
                </li>
                <li>
                <strong>Votre déclaration sera transmise aux services de l'Etat</strong>(D(R)EAL, DDT, Mairie) en charge de la réception de ces formalités.

                </li>
                <li>L’obtention du code BSS, identifiant unique pour chaque ouvrage concerné, se fera automatiquement au moment du dépôt de votre rapport de fin de forage</li>
            </ul>
            </div>
        </section>
        
        <!-- ACTUALITE -->
        <section id="S5-actu">
            
            <h2 id="attribtionCodeBSS">
            <img src="/assets/images/Polygon Mauve.png" alt="deco" />
            Actualité
            </h2>
            <div *ngFor="let actualite of lstActualite" class="blocActu" >
            <!-- Acticle 1 -->
                <div class="art-mayotte">
                    <img src="{{actualite.urlImage}}" alt="{{actualite.titre}}" />
                    <div>
                        <div>
                        <h3>{{actualite.titre}}</h3>
                        <h6>{{actualite.date}}</h6>
                        </div>
                        <p [innerHTML]="actualite.corps">
                        </p>
                    </div>
                </div>
            </div>
            <!-- Acticle 2 -->
            <!--div id="art-dialog">
            <img src="/assets/images/Carottes-brgm.png" alt="Carottes BRGM" />
            <div>
                <div>
                <h3>DiaLog s'assouplit</h3>
                <h6>Juin 2023</h6>
                </div>
                <p>
                Dans la description géologioque, la colonne " constituant " est
                désormais facultative et c'est la colonne " Description "
                (anciennement " Commentaire ") qui devient obligatoire : la saisie
                des descriptions géologiques redevient plus naturelle.
                <br /><br />Couplée à ce changement, une nouvelle fonctionnalité
                est apparue : si le premier mot de votre commentaire saisi est un
                terme du lexique lithologique BRGM, alors, la colonne "
                Constituant " se remplira automatiquement !<br /><br />Par
                exemple, si vous saisissez " Calcaire beige friable " dans le
                commentaire, alors " calcaire " apparaîtra automatiquement à côté.
                <br /><br />On rappel que pour obtenir une coupe géologique
                visuelle, les constituants restent nécessaires. Nous vous
                rappelons d'ailleurs que DiaLog vous permet, outre l'élaboration
                de coupes techniques et géologiques, de générer en ligne votre
                rapport de fin de forage, incluant d'éventuelles pièces jointes
                complémentaires. Nous vous encourageons à l'utiliser, cela
                facilitera aussi le travail des correspondants BSS en charge
                d'instruire les dossiers BSS.
                </p>
            </div>
            </div-->
            <!-- Scroll up -->
            <div class="flecheScrollup">
                <a href="#S1-hero"
                    ><img src="/assets/images/down.svg" alt="scroll up" id="scroll-up"
                /></a>
            </div>
        </section>
        </main>
        <footer>
        <div>
            <div>
            <img src="/assets/images/logo_brgm_web_fr_couleur2.svg.svg" alt="Logo BRGM" />
            <img src="/assets/images/RepFrancaise.svg" alt="Logo république francaise" />
            </div>
            <div id="foot-lien">
                <a href="#/mentions-legales">Informations légales</a>
                <a href="#/donnéespersonnelles">Données personnelles</a>
            </div>
            <div><p>©2023 DUPLOS BRGM</p></div>
        </div>
        </footer>

    <script src="app.js"></script>
    </div>
    <div *ngSwitchCase="true">
        <div *ngIf="!hasAccount()"  style="text-align: center;">
            <div class="logo">
                <i class="logo-img"></i>
            </div>
            <h2>DUPLOS</h2>
            <h3>Déclaration Unifiée Pour Les Ouvrages Souterrains</h3>
            <div class="row">
                <div class="col-md-6 offset-md-3">
                    <p><strong>Avant d'ajouter une déclaration, merci de compléter votre compte</strong></p>
                    <p><button type="button" class="btn btn-primary btn-md" (click)="createAccount()">Compléter mon compte</button></p>
                </div>
            </div>
        </div>
        <div *ngIf="hasAccount()" class="divDashBoard">
            <dup-dashboard></dup-dashboard>
        </div>
    </div>
</div>
